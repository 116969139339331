/* ---------- SEARCHBARS ---------- */
ion-searchbar.search {
  --box-shadow: none;
  --icon-color: var(--ion-color-primary-green);
  --placeholder-color: var(--ion-color-gray-60);
  --placeholder-opacity: 100%;
  border: 0.0625rem solid var(--ion-color-primary-green);
  border-radius: 1.875rem;
  height: 2rem;
  overflow: hidden;
  padding-inline-end: 0rem;
  padding-inline-start: 0rem;
  text-align: left;
  -webkit-padding-end: 0;
  -webkit-padding-start: 0;

  .searchbar-input-container {
    input {
      color: var(--ion-color-black);
      font-size: $font-size-14-px;
      padding-inline-end: 1.875rem;
      padding-inline-start: 2.5rem;
      -webkit-padding-end: 1.875rem;
      -webkit-padding-start: 2.5rem;
    }
  }
}

ion-searchbar.new-simple-search {
  width: 51.5rem;
  height: 2.25rem;
  border: 0.0625rem solid var(--ion-color-primary-green);
  --box-shadow: none;
  --icon-color: var(--ion-color-primary-green);
  --placeholder-color: var(--ion-color-gray-60);
  --placeholder-opacity: 100%;
  border-radius: 2.5rem;
  overflow: hidden;
  padding-inline-start: 0;
  margin-top: 1.5rem;

  @include isMobileAndTablet {
    width: 100%;
  }

  .searchbar-input-container {
    input {
      color: var(--ion-color-black);
      font-size: $font-size-12-px !important;
      padding-inline-end: 1.875rem;
      padding-inline-start: 2.5rem;
      -webkit-padding-end: 1.875rem;
      -webkit-padding-start: 3rem;
    }
  }
}

ion-searchbar.sub-search {
  .searchbar-input-container {
    input {
      font-size: $font-size-14-px !important;
      padding-left: 2.8125rem;
      padding-right: 2.1875rem;
      -webkit-padding-start: 2.8125rem;
      padding-inline-start: 2.8125rem;
      -webkit-padding-end: 2.1875rem;
      padding-inline-end: 2.1875rem;
      white-space: nowrap;
      overflow: hidden;
      -o-text-overflow: ellipsis;
      -ms-text-overflow: ellipsis;
      text-overflow: ellipsis;
      width: 15rem;
    }
  }
}

// Desktop Customer Search
ion-searchbar.customer-search {
  .searchbar-input-container {
    input {
      font-size: $font-size-14-px !important;
      padding-left: 20px;
    }
  }

  input.searchbar-input.sc-ion-searchbar-ios {
    padding-inline-start: 40px !important;
  }
}
.sc-ion-searchbar-ios-h {
  min-height: 2rem;
}
