@mixin toast-message($marginTop) {
  --border-radius: 0.5rem;
  --button-color: var(--ion-color-white);
  --color: var(--ion-color-white);
  --width: calc(100% - 2rem);
  --max-width: 75rem;
  margin-top: $marginTop !important;

  &::part(button) {
    font-size: $font-size-16-px;
    font-weight: 600;
    height: auto;
    text-decoration: underline !important;
    text-transform: none !important;
    width: auto;
  }
  &::part(message) {
    font-family: 'SF Pro Medium';
    font-size: $font-size-16-px;
  }
}

@mixin push-toast-content {
  --background: var(--ion-color-dark);
  --border-radius: 0.5rem;
  --button-color: var(--ion-color-white);
  --color: var(--ion-color-white);
  --max-width: 75rem;
  &::part(button) {
    font-size: $font-size-13-px;
    font-weight: 600;
    position: absolute;
    right: 0;
    top: 0;
  }
  &::part(header) {
    font-family: 'SF Pro Bold';
    font-size: $font-size-16-px;
  }
  &::part(icon) {
    flex-shrink: 0;
    font-size: $font-size-18-px;
  }
  &::part(message) {
    font-family: 'SF Pro Medium';
    font-size: $font-size-14-px;
    padding-right: 8%;
  }
}
