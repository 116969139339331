ion-menu.usf-desktop-flyout {
  --width: 31.25rem;

  &::part(container) {
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
  }
}

ion-menu.usf-mobile-flyout {
  --width: 100%;
  --min-width: 100%;

  &::part(container) {
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
    box-shadow: none;
  }
}

ion-accordion-group.facet-flyout-accordion-group {
  ion-accordion {
    .ion-accordion-toggle-icon {
      font-size: 18px;
    }
  }
}
