/* ----- ACTIONSHEETS ------ */
ion-action-sheet.action-sheet-touch {
  --background: var(--ion-color-white);
  --color: var(--ion-color-primary-green);

  .action-sheet-button.sc-ion-action-sheet-ios {
    font-family: 'SF Pro Medium';
    font-size: $font-size-16-px;
  }
}

.aog-tablet-button {
  height: 4.625rem;
  ion-icon {
    -webkit-order: 2;
    order: 2;
    margin-left: 0.5rem;
    font-size: 6rem !important;
  }
}

.ai-list-builder-button {
  height: 4.625rem;
  ion-icon {
    -webkit-order: 2;
    order: 2;
    margin-left: 0.5rem;
    font-size: 4rem !important;
  }
}
