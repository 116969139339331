// Custom Theming for Angular Material
@use '@angular/material' as mat;
// For more information: https://material.angular.io/guide/theming
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
@include mat.all-legacy-component-typographies();
@include mat.legacy-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$app-primary: mat.define-palette(mat.$indigo-palette);
$app-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$app-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$app-theme: mat.define-light-theme(
  (
    color: (
      primary: $app-primary,
      accent: $app-accent,
      warn: $app-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-legacy-component-themes($app-theme);

// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/

:root {
  /** Green Primary  **/
  --ion-color-primary-green: #56821f;
  --ion-color-primary-green-hover: #3f6017;
  --ion-color-primary-green-20: #dde6d2;
  --ion-color-primary-green-5: #f7f9f4;

  /** Highlight **/
  --highlight-color-valid: #56821f !important;
  --highlight-color-invalid: #cf4520 !important;
  --highlight-color: #56821f !important;

  /** Orange Secondary **/
  --ion-color-secondary-orange: #cf4520;
  --ion-color-secondary-orange-hover: #a33619;
  --ion-color-secondary-orange-20: #f5dad2;
  --ion-color-secondary-orange-5: #fdf6f4;

  /** Grayscale **/
  --ion-color-gray-80: #767676 !important; //Used for Secondary Disabled Border and Text
  --ion-color-gray-60: #b6b6b6 !important; //Used for Borders, Dividers, Placeholder Text, Radio/Toggle Disabled
  --ion-color-gray-40: #dadada !important; //Used for Background of Primary Disabled Button
  --ion-color-gray-30: #ebebeb !important; //Used for Background of Product Card Drawer
  --ion-color-gray-20: #f3f3f3 !important; //Used for Background of Inputs, Secondary Disabled Buttons, No Image Product Backgrounds
  --ion-color-gray-70: #585858 !important;

  /** Gray Page Color **/
  --ion-color-gray-page-background: #f6f6f6; //Used for Gray Page Background

  /** Black and White **/
  --ion-color-white: #ffffff;
  --ion-color-black: #2f2f2f; // Default dark text

  /** Yellow **/
  --ion-color-yellow: #f3ba16;
  --ion-color-light-yellow: #fdf1d0;

  /** Do Not Use Variables Below **/
  /** Default Ionic Values -- Use Above Variables First **/
  --ion-color-primary: #56821f !important;
  --ion-color-primary-rgb: 86, 130, 31 !important;
  --ion-color-primary-contrast: #ffffff !important;
  --ion-color-primary-rgb: 255, 255, 255 !important;
  --ion-color-primary-shade: #4c721b !important;
  --ion-color-primary-tint: #678f35 !important;

  --ion-color-secondary: #cf4520 !important;
  --ion-color-secondary-rgb: 207, 69, 32;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #b63d1c;
  --ion-color-secondary-tint: #d45836;

  --ion-color-tertiary: #b6b6b6;
  --ion-color-tertiary-rgb: 182, 182, 182;
  --ion-color-tertiary-contrast: #000000;
  --ion-color-tertiary-contrast-rgb: 0, 0, 0;
  --ion-color-tertiary-shade: #a0a0a0;
  --ion-color-tertiary-tint: #bdbdbd;

  --ion-color-success: #56821f !important;
  --ion-color-success-rgb: 86, 130, 31;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #4c721b;
  --ion-color-success-tint: #678f35;

  --ion-color-warning: #cf4520 !important;
  --ion-color-warning-rgb: 207, 69, 32 !important;
  --ion-color-warning-contrast: #ffffff !important;
  --ion-color-warning-contrast-rgb: 255, 255, 255 !important;
  --ion-color-warning-shade: #b63d1c !important;
  --ion-color-warning-tint: #d45836 !important;

  --ion-color-danger: #cf4520 !important;
  --ion-color-danger-rgb: 207, 69, 32 !important;
  --ion-color-danger-contrast: #ffffff !important;
  --ion-color-danger-contrast-rgb: 255, 255, 255 !important;
  --ion-color-danger-shade: #b63d1c !important;
  --ion-color-danger-tint: #d45836 !important;

  --ion-color-dark: #2f2f2f;
  --ion-color-dark-rgb: 47, 47, 47;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #292929;
  --ion-color-dark-tint: #444444;

  --ion-color-medium: #b6b6b6;
  --ion-color-medium-rgb: 216, 216, 216;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0, 0, 0;
  --ion-color-medium-shade: #bebebe;
  --ion-color-medium-tint: #dcdcdc;

  --ion-color-white: #ffffff;
  --ion-color-white-rgb: 255, 255, 255;
  --ion-color-white-contrast: #000000;
  --ion-color-white-contrast-rgb: 0, 0, 0;
  --ion-color-white-shade: #dadada;
  --ion-color-white-tint: #f9f9f9;

  --ion-color-olive: #dde6d2;
  --ion-color-olive-rgb: 221, 230, 210;
  --ion-color-olive-contrast: #000000;
  --ion-color-olive-contrast-rgb: 0, 0, 0;
  --ion-color-olive-shade: #c2cab9;
  --ion-color-olive-tint: #e0e9d7;
}

@font-face {
  font-family: 'Oswald ExtraLight';
  src: url('../assets/fonts/Oswald-200.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'Oswald Light';
  src: url('../assets/fonts/Oswald-300.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'Oswald Regular';
  src: url('../assets/fonts/Oswald-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'Oswald Medium';
  src: url('../assets/fonts/Oswald-500.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'Oswald Semibold';
  src: url('../assets/fonts/Oswald-600.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'Oswald Bold';
  src: url('../assets/fonts/Oswald-700.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'SF Pro Bold';
  src: url('../assets/fonts/SFProDisplay-Bold.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'SF Pro BlackItalic';
  src: url('../assets/fonts/SFProDisplay-BlackItalic.woff2') format('woff2');
  font-weight: 900;
  font-style: italic;
  font-display: block;
}

@font-face {
  font-family: 'SF Pro Black';
  src: url('../assets/fonts/SFProDisplay-Black.woff2') format('woff2');
  font-weight: 900;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'SF Pro BoldItalic';
  src: url('../assets/fonts/SFProDisplay-BoldItalic.woff2') format('woff2');
  font-weight: bold;
  font-style: italic;
  font-display: block;
}

@font-face {
  font-family: 'SF Pro Heavy';
  src: url('../assets/fonts/SFProDisplay-Heavy.woff2') format('woff2');
  font-weight: 900;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'SF Pro HeavyItalic';
  src: url('../assets/fonts/SFProDisplay-HeavyItalic.woff2') format('woff2');
  font-weight: 900;
  font-style: italic;
  font-display: block;
}

@font-face {
  font-family: 'SF Pro Light';
  src: url('../assets/fonts/SFProDisplay-Light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'SF Pro LightItalic';
  src: url('../assets/fonts/SFProDisplay-LightItalic.woff2') format('woff2');
  font-weight: 300;
  font-style: italic;
  font-display: block;
}

@font-face {
  font-family: 'SF Pro Medium';
  src: url('../assets/fonts/SFProDisplay-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'SF Pro MediumItalic';
  src: url('../assets/fonts/SFProDisplay-MediumItalic.woff2') format('woff2');
  font-weight: 500;
  font-style: italic;
  font-display: block;
}

@font-face {
  font-family: 'SF Pro RegularItalic';
  src: url('../assets/fonts/SFProDisplay-RegularItalic.woff2') format('woff2');
  font-weight: normal;
  font-style: italic;
  font-display: block;
}

@font-face {
  font-family: 'SF Pro Regular';
  src: url('../assets/fonts/SFProDisplay-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

:root {
  --ion-font-family: 'SF Pro Regular', sans-serif !important;
  font-family: 'SF Pro Regular', sans-serif !important;
}

:root .bold {
  --ion-font-family: 'SF Pro Bold', sans-serif !important;
  font-family: 'SF Pro Bold', sans-serif !important;
}

// IOS
:root[mode='ios'] {
  --ion-font-family: 'SF Pro Regular', sans-serif !important;
  font-family: 'SF Pro Regular', sans-serif !important;
}

:root[mode='ios'] .bold {
  --ion-font-family: 'SF Pro Bold', sans-serif !important;
  font-family: 'SF Pro Bold', sans-serif !important;
}

//WP
:root[mode='wp'] {
  --ion-font-family: 'SF Pro Regular', sans-serif !important;
  font-family: 'SF Pro Regular', sans-serif !important;
}

:root[mode='wp'] .bold {
  --ion-font-family: 'SF Pro Bold', sans-serif !important;
  font-family: 'SF Pro Bold', sans-serif !important;
}

//MD
:root[mode='md'] {
  --ion-font-family: 'SF Pro Regular', sans-serif !important;
  font-family: 'SF Pro Regular', sans-serif !important;
}

:root[mode='md'] .bold {
  --ion-font-family: 'SF Pro Bold', sans-serif !important;
  font-family: 'SF Pro Bold', sans-serif !important;
}

@media (prefers-color-scheme: darks) {
  /*
   * Dark Colors
   * -------------------------------------------
   */

  body {
    //   --ion-color-primary: #428cff;
    //   --ion-color-primary-rgb: 66, 140, 255;
    //   --ion-color-white: #ffffff;
    //   --ion-color-white-rgb: 255, 255, 255;
    //   --ion-color-primary-shade: #3a7be0;
    //   --ion-color-primary-tint: #5598ff;

    //   --ion-color-secondary-orange: #50c8ff;
    //   --ion-color-secondary-orange-rgb: 80, 200, 255;
    //   --ion-color-secondary-orange-contrast: #ffffff;
    //   --ion-color-secondary-orange-contrast-rgb: 255, 255, 255;
    //   --ion-color-secondary-orange-shade: #46b0e0;
    //   --ion-color-secondary-orange-tint: #62ceff;

    //   --ion-color-tertiary: #6a64ff;
    //   --ion-color-tertiary-rgb: 106, 100, 255;
    //   --ion-color-tertiary-contrast: #ffffff;
    //   --ion-color-tertiary-contrast-rgb: 255, 255, 255;
    //   --ion-color-tertiary-shade: #5d58e0;
    //   --ion-color-tertiary-tint: #7974ff;

    //   --ion-color-primary-green: #2fdf75;
    //   --ion-color-primary-green-rgb: 47, 223, 117;
    //   --ion-color-primary-green-contrast: #000000;
    //   --ion-color-primary-green-contrast-rgb: 0, 0, 0;
    //   --ion-color-primary-green-shade: #29c467;
    //   --ion-color-primary-green-tint: #44e283;

    //   --ion-color-secondary-orange: #ffd534;
    //   --ion-color-secondary-orange-rgb: 255, 213, 52;
    //   --ion-color-secondary-orange-contrast: #000000;
    //   --ion-color-secondary-orange-contrast-rgb: 0, 0, 0;
    //   --ion-color-secondary-orange-shade: #e0bb2e;
    //   --ion-color-secondary-orange-tint: #ffd948;

    //   --ion-color-secondary-orange: #ff4961;
    //   --ion-color-secondary-orange-rgb: 255, 73, 97;
    //   --ion-color-secondary-orange-contrast: #ffffff;
    //   --ion-color-secondary-orange-contrast-rgb: 255, 255, 255;
    //   --ion-color-secondary-orange-shade: #e04055;
    //   --ion-color-secondary-orange-tint: #ff5b71;

    //   --ion-color-dark: #f4f5f8;
    //   --ion-color-dark-rgb: 244, 245, 248;
    //   --ion-color-dark-contrast: #000000;
    //   --ion-color-dark-contrast-rgb: 0, 0, 0;
    //   --ion-color-dark-shade: #d7d8da;
    //   --ion-color-dark-tint: #f5f6f9;

    //   --ion-color-medium: #989aa2;
    //   --ion-color-medium-rgb: 152, 154, 162;
    //   --ion-color-medium-contrast: #000000;
    //   --ion-color-medium-contrast-rgb: 0, 0, 0;
    //   --ion-color-medium-shade: #86888f;
    //   --ion-color-medium-tint: #a2a4ab;

    //   --ion-color-gray-page-background: #222428;
    //   --ion-color-gray-page-background-rgb: 34, 36, 40;
    //   --ion-color-gray-page-background-contrast: #ffffff;
    //   --ion-color-gray-page-background-contrast-rgb: 255, 255, 255;
    //   --ion-color-gray-page-background-shade: #1e2023;
    //   --ion-color-gray-page-background-tint: #383a3e;
  }

  /*
   * iOS Dark Theme
   * -------------------------------------------
   */

  .ios body {
    //   --ion-background-color: #000000;
    //   --ion-background-color-rgb: 0, 0, 0;

    //   --ion-text-color: #ffffff;
    //   --ion-text-color-rgb: 255, 255, 255;

    //   --ion-color-step-50: #0d0d0d;
    //   --ion-color-step-100: #1a1a1a;
    //   --ion-color-step-150: #262626;
    //   --ion-color-step-200: #333333;
    //   --ion-color-step-250: #404040;
    //   --ion-color-step-300: #4d4d4d;
    //   --ion-color-step-350: #595959;
    //   --ion-color-step-400: #666666;
    //   --ion-color-step-450: #737373;
    //   --ion-color-step-500: #808080;
    //   --ion-color-step-550: #8c8c8c;
    //   --ion-color-step-600: #999999;
    //   --ion-color-step-650: #a6a6a6;
    //   --ion-color-step-700: #b3b3b3;
    //   --ion-color-step-750: #bfbfbf;
    //   --ion-color-step-800: #cccccc;
    //   --ion-color-step-850: #d9d9d9;
    //   --ion-color-step-900: #e6e6e6;
    //   --ion-color-step-950: #f2f2f2;

    //   --ion-item-background: #000000;

    //   --ion-card-background: #1c1c1d;
  }

  .ios ion-modal {
    //   --ion-background-color: var(--ion-color-step-100);
    //   --ion-toolbar-background: var(--ion-color-step-150);
    //   --ion-toolbar-border-color: var(--ion-color-step-250);
  }

  /*
   * Material Design Dark Theme
   * -------------------------------------------
   */

  .md body {
    //   --ion-background-color: #121212;
    //   --ion-background-color-rgb: 18, 18, 18;

    //   --ion-text-color: #ffffff;
    //   --ion-text-color-rgb: 255, 255, 255;

    //   --ion-border-color: #222222;

    //   --ion-color-step-50: #1e1e1e;
    //   --ion-color-step-100: #2a2a2a;
    //   --ion-color-step-150: #363636;
    //   --ion-color-step-200: #414141;
    //   --ion-color-step-250: #4d4d4d;
    //   --ion-color-step-300: #595959;
    //   --ion-color-step-350: #656565;
    //   --ion-color-step-400: #717171;
    //   --ion-color-step-450: #7d7d7d;
    //   --ion-color-step-500: #898989;
    //   --ion-color-step-550: #949494;
    //   --ion-color-step-600: #a0a0a0;
    //   --ion-color-step-650: #acacac;
    //   --ion-color-step-700: #b8b8b8;
    //   --ion-color-step-750: #c4c4c4;
    //   --ion-color-step-800: #d0d0d0;
    //   --ion-color-step-850: #dbdbdb;
    //   --ion-color-step-900: #e7e7e7;
    //   --ion-color-step-950: #f3f3f3;

    //   --ion-item-background: #1e1e1e;

    //   --ion-toolbar-background: #1f1f1f;

    //   --ion-tab-bar-background: #1f1f1f;

    //   --ion-card-background: #1e1e1e;
  }
}

html,
body {
  height: 100%;
}
